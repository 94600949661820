import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Footer from "../components/footer"
import Button from "react-bootstrap/Button"
import { Link, navigate } from "gatsby"
import { Official, Anonymous } from "../components/registration-types"

const RegisterPage = () => {
  return (
    <Layout>
      <SEO title="THACO | Register" name="register" />
      <Header page="register" />
      <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
        <div className="container" style={{ maxWidth: 800 }}>
          <h2 className="text-center mb-5">
            <span className="bg-dark px-2 py-1">สมัครเข้าร่วมแข่งขัน</span>
          </h2>
          <div className="text-left text-white mb-3">
            <h4>การแข่งขัน</h4>
            <p>
              การแข่งขัน Thailand Computing Olympiad 2020
              เป็นการแข่งขันที่จัดขึ้นโดยกลุ่มนักเรียนเก่าค่ายคอมพิวเตอร์โอลิมปิก
              การแข่งขันนี้แบ่งออกเป็น 2 วัน แต่ละวันจะมีระยะเวลาการแข่งขันยาว 3
              ชั่วโมง{" "}
              <Link to="/schedule" className="sans pb-0">
                (ดูกำหนดการได้ที่นี่)
              </Link>
            </p>
            <p>
              ภายใต้เวลาการแข่งขัน ผู้เข้าแข่งขันทุกคนจะได้รับโจทย์เหมือนกัน 3
              ข้อ เป็นโจทย์แข่งขันคอมพิวเตอร์โอลิมปิก
              สามารถเทียบเคียงได้กับโจทย์ใน{" "}
              <Link className="sans pb-0" to="https://ioinformatics.org">
                การแข่งขันคอมพิวเตอร์โอลิมปิกระหว่างประเทศ (International
                Olympiad in Informatics, IOI)
              </Link>{" "}
              แต่จะมีข้อแตกต่างคือ การแข่งขันนี้จะใช้
              <Link to="/scope" className="sans pb-0">
                ขอบเขตเนื้อหา
              </Link>
              ไม่เกินการแข่งขันคอมพิวเตอร์โอลิมปิกระดับชาติ (Thailand Olympiad
              in Informatics, TOI)
            </p>
            <p>
              โจทย์ทุกข้อจะใช้การเขียนอ่านข้อมูลทาง standard input (คีย์บอร์ด)
              และ standard output (หน้าจอ) เท่านั้น จะไม่มีโจทย์ประเภท
              interactive หรือ output-only และจะอนุญาตให้ใช้เฉพาะภาษา C และ C++
              เท่านั้น โดยมีรุ่นของภาษาเป็น C11 และ C++11 ตามลำดับ
              การแข่งขันจะใช้{" "}
              <Link className="sans pb-0" to="https://cms-dev.github.io/">
                ระบบจัดการแข่งขัน CMS (Contest Management System)
              </Link>{" "}
              ซึ่งเป็นระบบเดียวกับที่ใช้ในการแข่งขันคอมพิวเตอร์โอลิมปิกระหว่างประเทศ
            </p>
            <h4>การคิดคะแนน</h4>
            <p>
              สำหรับการคิดคะแนนจะเป็นระบบ Complete Feedback กล่าวคือ
              ผู้เข้าแข่งขันจะสามารถเห็นคะแนนของตนเองได้ทันทีที่ส่ง
              และจะส่งได้ไม่จำกัดจำนวนครั้งที่ส่ง แต่ห้ามส่งถี่มากเกินไป
              กล่าวคือจะต้องรออย่างน้อย 30 วินาทีสำหรับการส่งครั้งถัดไป
            </p>
            <p>
              ในโจทย์แต่ละข้อเราจะแบ่งออกเป็นปัญหาย่อย (subtasks) หลายๆ
              ปัญหาย่อย ในแต่ละปัญหาย่อยจะประกอบด้วยข้อมูลทดสอบหลายชุด
              ผู้เข้าแข่งขันจะได้คะแนนเต็มของปัญหาย่อย
              ก็ต่อเมื่อผู้เข้าแข่งขันได้คะแนนเต็มของข้อมูลทดสอบทุกชุดในปัญหาย่อยนั้นๆ
              หากมีการผิดพลาดแม้แต่ชุดทดสอบเดียว
              จะถือว่าปัญหาย่อยนั้นได้คะแนนเป็นศูนย์
              กฎดังกล่าวอาจมีการเปลี่ยนแปลง
              และจะมีการแจ้งในโจทย์แต่ละข้อหากมีการคิดคะแนนที่แตกต่างออกไป (เช่น
              ไม่มีปัญหาย่อย นับคะแนนตามจำนวนชุดทดสอบ หรือ
              นับคะแนนตามประสิทธิภาพของคำตอบ)
            </p>
            <p>
              หากผู้เข้าแข่งขันส่งหลายครั้ง
              คะแนนสุดท้ายที่จะได้รับคือผลรวมของคะแนนสุดท้ายในแต่ละปัญหาย่อย
              และคะแนนสุดท้ายในแต่ละปัญหาย่อย
              คือค่ามากสุดของคะแนนที่ได้รับในปัญหาย่อยนั้นจากการส่งทุกครั้งที่ผ่านมา
            </p>
            <h4>ประเภทการลงทะเบียนการแข่งขัน</h4>
            <p>
              การลงทะเบียนจะแบ่งออกเป็น ประเภท <Official /> และ ประเภท{" "}
              <Anonymous /> <br />
              โดยสำหรับการแข่งขันทั้งสองประเภท
              จะต่างกันเฉพาะรางวัลที่จะได้รับและข้อมูลที่เราเก็บ
              การแข่งขันประเภท <Official /> จะได้รับรางวัลตามปกติ
              แต่เราจะเก็บข้อมูลที่จำเป็นต่อการจัดส่งรางวัล เช่น ชื่อ ที่อยู่
              และข้อมูลสำคัญอื่นๆ การแข่งขันประเภทนี้
              เหมาะสำหรับบุคคลทั่วไปทุกเพศทุกวัย ที่สะดวกรับของรางวัล <br />
              ส่วนสำหรับประเภท <Anonymous /> เราจะไม่ส่งรางวัลใดๆ
              และไม่นำมาคิดคะแนนการแข่งขัน เหมาะสำหรับผู้ที่ไม่สะดวกรับของรางวัล
              แต่อยากได้รับประสบการณ์การแข่งขัน
            </p>
            <p>
              ระหว่างการแข่งขันจะมีการแสดงตารางคะแนนสด ผู้เข้าแข่งขันประเภท{" "}
              <Official /> จะมีคะแนนแสดงให้เห็นต่อสาธารณะ
              แต่ผู้เข้าแข่งขันประเภท <Anonymous />{" "}
              จะไม่มีการแสดงคะแนนต่อสาธารณะ
            </p>
            <h4>รางวัลที่จะได้รับ</h4>
            <p>
              เราจะตัดแบ่งผู้เข้าแข่งขันเป็นเภท <Official /> ออกเป็น 12 ส่วน
              เรียงตามคะแนนจากมากไปน้อย แล้วให้อัตราส่วน เหรียญทอง ต่อ
              เหรียญเงิน ต่อ เหรียญทองแดง ต่อ ไม่ได้รับเหรียญ เป็น 1 ต่อ 2 ต่อ 3
              ต่อ 6
            </p>
            <p>
              ผู้ที่ได้รางวัลเหรียญใดๆ ก็ตาม จะได้รับประกาศนียบัตรอิเล็กทรอนิกส์
              เป็นไฟล์ PDF และได้รับเสื้อเป็นรางวัล ส่งไปตามที่อยู่ที่ได้รับแจ้ง
            </p>
            <h4>เงื่อนไขของการสมัคร</h4>
            <p>
              ผู้เข้าแข่งขันเป็น บุคคลทั่วไป ทุกเพศ ทุกวัย
              ไม่มีการจำกัดอายุหรือสถานะใดๆ
            </p>
          </div>
        </div>
      </section>
      <section
        className="py-5 text-white flex-center-col"
        style={{ backgroundColor: "#141414", minHeight: 300 }}
      >
        <div className="container" style={{ maxWidth: 800 }}>
          <h4 className="text-center">
            ปิดรับสมัครการแข่งขันแล้ว
          </h4>
         
        </div>
      </section>
      <Footer style={{ borderTop: 0 }} />
    </Layout>
  )
}

export default RegisterPage
