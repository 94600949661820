import React from 'react'
export const Official = () => {
  return <span className='monospace green font-weight-bold'>
    Official
  </span>
}
export const Anonymous = () => {
  return <span className='monospace blue font-weight-bold'>
    Anonymous
  </span>
}